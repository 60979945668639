<template>
  <div>
    <div class="page">
      <div class="formcontainer">
        <div class="formbox">
          <h2>Hizmet Sağlayıcılar için Başvuru Formu</h2>
          <tabs-new-basvuru current="2" hide="6"></tabs-new-basvuru>
          <tabs-edevlet-basvuru-sub v-if="loggedIn" current="2" hide="6"></tabs-edevlet-basvuru-sub>
        <tabs-eimza-basvuru-sub v-else current="2" hide="6"></tabs-eimza-basvuru-sub>

          <transition name="fade">
            <div class="formpart">
              <div class="formleft">
                <h3>Ticari Bilgiler</h3>

                <form action="#" onsubmit="return false;">
                  <iys-input
                    label="MERSİS Numarası"
                    v-model="application.mersisNo"
                    mask="9999999999999999"
                    :problem="problem.mersisNo"
                    @input="problem.mersisNo = ''"
                    :fixed="true"
                  />

                  <!-- <iys-input
                    label="Unvan"
                    v-model="application.title"
                    :fixed="true"
                  />-->
                  <div class="forminput">
                    <label>Unvan</label>
                    <textarea
                      :rows="parseInt(application.title.length / 20)"
                      class="input_fix_murat"
                      v-model="application.title"
                      readonly
                    ></textarea>
                  </div>

                  <iys-input
                    id="vkn"
                    label="Vergi Kimlik Numarası"
                    v-model="application.vergiNo"
                    :fixed="true"
                  />

                  <div v-if="vergi_dairesi_listesi.length > 0" class="forminput">
                    <label>Vergi Dairesi</label>
                    <select id="vkd" v-model="vergiDairesiKodu">
                      <option
                        v-for="(item, index) in vergi_dairesi_listesi"
                        :key="index"
                        :value="item.code"
                      >{{ item.name }}</option>
                    </select>
                    <div class="hint"></div>
                  </div>

                  <iys-input
                    v-else
                    id="vkd"
                    label="Vergi Dairesi"
                    :sanitize="false"
                    v-model="vergiDairesi"
                  />

                  <iys-input
                    id="website"
                    name="website"
                    label="Web Siteniz"
                    v-model="website"
                    :problem="problem.website"
                    @input="problem.website = ''"
                  />

                  <iys-input
                    id="officePhone"
                    name="office-phone"
                    label="İletişim Numarası"
                    v-model="officePhone"
                    mask="(9999) 999-99-99"
                    :problem="problem.officePhone"
                    @input="problem.officePhone = ''"
                  >
                    <tool-tip>
                      Lütfen hesap yöneticilerimizin size ulaşmasını
                      istediğiniz kurumsal telefon numaranızı yazınız.
                    </tool-tip>
                  </iys-input>

                  <iys-input label="İl" v-model="application.address.city" :fixed="true" />

                  <iys-input label="İlçe" v-model="application.address.town" :fixed="true" />

                  <div class="forminput">
                    <label>İletişim Adresi</label>
                    <textarea
                      :rows="
                        parseInt(
                          application.address.explicitAddress.length / 20
                        )
                      "
                      class="input_fix_murat"
                      v-model="application.address.explicitAddress"
                      readonly
                    ></textarea>
                  </div>
                </form>
              </div>

              <div class="formright">
                <h3>Yetkili Kişi Bilgileri</h3>

                <form action="#" onsubmit="return false;">
                  <iys-input
                    label="TC Kimlik Numarası"
                    v-model="application.contact.tcKimlikNo"
                    mask="99999999999"
                    :problem="problem.tckn"
                    :fixed="true"
                  />

                  <iys-input
                    label="Ad Soyad"
                    v-model="application.contact.name"
                    :problem="problem['contact.name']"
                    :fixed="true"
                  />

                  <iys-input
                    label="E-posta"
                    name="email"
                    v-model="application.contact.email"
                    :problem="problem['contact.email']"
                    :fixed="true"
                  />

                  <!-- <iys-input
                    v-if="cepTelDogrulaState === 'INIT'"
                    id="contactCellPhone"
                    name="cell-phone"
                    label="Cep Telefonu"
                    v-model="contactCellPhone"
                    mask="(9999) 999-99-99"
                    placeholder="(05xx) xxx-xx-xx"
                    :problem="problem['cellPhone']"
                    @input="problem['cellPhone'] = ''"
                  >
                    <tool-tip>
                      Lütfen doğrulama kodunun gönderileceği cep telefonu
                      numaranızı yazınız.
                    </tool-tip>
                  </iys-input>

                  <cep-tel-dogrulama v-model="cepTelDogrulaState" :number="contactCellPhone" />

                  <hizmet-sozlesmesi-kabul
                    v-if="CEPTELDOGRULANDI"
                    v-model="hizmet_sozlesmesini_okudum"
                  ></hizmet-sozlesmesi-kabul>-->

                  <aydinlatma-metni-kabul v-model="hizmet_sozlesmesini_okudum"></aydinlatma-metni-kabul>
                </form>
              </div>
            </div>
          </transition>

          <sonraki-bar :dsbl="!next" @next="next ? NEXT() : null" :no-app-no="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CepTelDogrulama from "./genel/CepTelDogrulama";
import SonrakiBar from "./genel/SonrakiBar";

export default {
  name: "Form",

  components: {
    CepTelDogrulama,
    SonrakiBar,
  },

  data() {
    return {
      cepTelDogrulaState: "",

      vergiDairesi: "",
      vergiDairesiKodu: "",
      vergi_dairesi_listesi: [],

      website: "",
      officePhone: "",
      contactCellPhone: "",

      hizmet_sozlesmesini_okudum: false,

      problem: {
        website: "", // TODO: apiden kontrolet
        officePhone: "",
        cellPhone: "",
      },
    };
  },

  mounted() {
    if (this.$env.MOCK) {
      this.MOCK();
    }

    this.vergiDairesi = this.application.vergiDairesi;
    this.vergiDairesiKodu = this.application.vergiDairesiKodu;

    this.get_vergi_dairesi(this.application.address.city)
      .then((res) => {
        // _paq.push([
        //   "trackEvent",
        //   "Basvuru",
        //   "Form_Vergi_Dairesi_Sorgula",
        //   "Success",
        // ]);
        if (res.ok) {
          this.vergi_dairesi_listesi = [...res.payload];
        }
      })
      .catch((cerror) => {
        // _paq.push([
        //   "trackEvent",
        //   "Basvuru",
        //   "Form_Vergi_Dairesi_Sorgula",
        //   "Error",
        // ]);
        // this.HandleValidationError(cerror);
      });
  },

  computed: {
    ...mapState("applicant", ["user", "application"]),

    CEPTELDOGRULANDI() {
      return this.cepTelDogrulaState === "SUCCESS";
    },

    next() {
      return true;
      // return this.CEPTELDOGRULANDI;
    },
  },

  methods: {
    ...mapActions("applicant", ["save_basvuru", "get_vergi_dairesi"]),
    ...mapMutations("applicant", ["MERGE_TO_APPLICATION"]),

    NEXT() {
      if (!this.hizmet_sozlesmesini_okudum) {
        this.Alert(
          "Devam edebilmek için Hizmet Sağlayıcı Aydınlatma Metni'ni okuyup kabul etmeniz gerekmektedir."
        );
        return;
      }

      this.clearValidationErrorDisplays();

      const cellPhone = this.user.mobile;

      this.MERGE_TO_APPLICATION({
        vergiDairesi:
          this.vergi_dairesi_listesi.length > 0
            ? this.vergi_dairesi_listesi.find(
                (e) => e.code === this.vergiDairesiKodu
              ).name
            : this.vergiDairesi,
        vergiDairesiKodu: this.vergiDairesiKodu,
        cellPhone,
        website: this.website,
        officePhone: this.officePhone,
        contact: {
          cellPhone,
        },
      });

      this.$env.CONSOLE.log("SAVE EDILECEK VUEX BASVURU NESNESI:");
      this.$env.CONSOLE.log(JSON.parse(JSON.stringify(this.application)));

      this.save_basvuru(this.application)
        .then((res) => {
          // _paq.push(["trackEvent", "Basvuru", "Form_Kaydet", "Success"]);
          this.$router.push(
            "/hizmet-saglayici/basvuru/belge-yukleme-on-basvuru"
          );
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Basvuru", "Form_Kaydet", "Error"]);
          this.HandleValidationError(cerror);
        });
    },

    MOCK() {
      this.Toast(`Development mode: Filling form with mock data!!!`);

      this.website = "www.yetkilicicekcilik.com";
      this.officePhone = "(0212) 111-11-11";
      this.contactCellPhone = "(0537) 711-62-50";

      this.hizmet_sozlesmesini_okudum = true;
    },
  },
};
</script>

<style scoped>
.input_fix_murat {
  /* overflow: scroll; */
  pointer-events: none;
  background-color: #ebf6ff;
  border: #ffffff;
  font-weight: 600;
}

.pointer_murat {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.izinsection {
  display: flex;
  flex-direction: column;
  margin: 4vh auto;
  width: calc(100% - 4vh);
  border-radius: 0.5vw;
  padding: 1vw;
}

.izinsection label {
  color: #55565e;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
}

.izintable .izinline .title {
  margin-right: 0;
  text-align: left;
}
.izintable .izinline .radio-group {
  justify-content: center;
}

.hizmet-sozlesmesi-all .hizmet-sozlesmesi {
  display: block;
  height: 20vh;
  background: #f9f9f9;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0.5vw;
  border: 0.5vw #f9f9f9 solid;
  border-radius: 0.5vw;
  margin-bottom: 1vw;
}
</style>
